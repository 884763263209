.seoimg {
    background-image: url("/public/assets/backgroundimg/backgroung/seo.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    height: 100vh;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
}

.seo-title {
    font-size: 43px !important;
    color: black !important;
}

.seo-title-color {
    color: black !important;

}

.content-seo-main-section-one {
    color: black;
    background: #ffffff;
    display: flex;
    padding: 0rem 4rem;
    max-width: 100%;
    width: 2500px;
    margin: auto;
}

@media screen and (max-width:1000px) {
    .content-seo-main-section-one {
        padding: 20px !important;
        flex-wrap: wrap;
    }

    .child-seo-paragrapg {
        margin-left: 0px;
    }

    .seo-title {
        font-size: 33px !important;
    }

}

h2.title-seo-headind {
    font-size: 31px;
    font-weight: 600;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    text-align: center;
}

.child-seo-paragrapg {
    border-left: 4px solid #00bbbe;
    padding-left: 1.7rem;
    padding-top: 1.7rem;
    padding-bottom: 1.7rem;
}

.content-seo-one {
    padding-right: 1.5rem;
}

.just-content-para-seo {
    /* padding: 0rem 1rem; */
    text-align: justify;
}

p.para-seo-content-p {
    text-align: justify;
}

.content-seo-two {
    margin-top: 3rem;
    max-width: 100%;
    width: 100%;
}

p.para-seo-content {
    margin-bottom: 1.6rem;
}

.btn-seo-contact-us {
    text-decoration: none;
    margin-left: 1.8rem;
    font-weight: 600;
    background-image: linear-gradient(180deg, #00bbbe, #095d5e);
    border-radius: 4px;
    color: black;
    padding: 0.7rem 1rem;
}

.card-content-child-main-container {
    background: #202124;
    max-width: 100%;
    width: 75%;
    color: white;
    border-radius: 20px;
    margin-bottom: 2rem;
}

.card-search-seo {
    text-align: justify;
}

h2.title-headin {
    font-size: 18px;
    font-weight: 900;
    margin-bottom: 1rem;
    color: black;
}

p.p-seo-content {
    margin-bottom: 0.7rem;
}

.card-seo-big-card-content-container {
    padding: 0rem 4rem;
}

.child-content-main-title-seo {
    padding: 3rem 4.2rem;
    font-size: 33px;
    max-width: 100%;
    width: 2500px;
    margin: auto;
    font-weight: 900;
}

.container-content-card-seo {
    max-width: 100%;
    width: 2500px;
    margin: auto;
}

.title-seo-head {
    width: fit-content;
    border-bottom: 10px solid #1cc0c3;
    color: black;
}

.content-card-one {
    display: flex;
    flex-direction: row-reverse;
}

.content-card-two {
    display: flex;
    flex-direction: row;
}

.img-icon-seo {
    padding-top: 17px;
}

img.icon-seo-tecnical {
    max-width: 150px;
}

.para-car-content-p-element {
    font-size: 0.9rem;
}

@media screen and (max-width:900px) {
    .card-content-child-main-container {
        max-width: none;
        width: 100%;
    }

    .content-seo-one {
        padding-right: 0px !important;
    }

    h2.title-headin {
        font-size: 18px !important;
    }

    .card-seo-big-card-content-container {
        padding: 0rem 20px;
    }

    .content-card-one {
        flex-direction: column;
    }

    .content-card-two {
        flex-direction: column;
    }

    .img-icon-seo {
        display: flex;
        width: 100%;
        justify-content: center;
    }

    img.icon-seo-tecnical {
        max-width: 127px;
    }

    h2.title-tiktok {
        font-size: 30px !important;

    }
    .Tktok-content {
        margin-top: 2rem!important;
        margin-bottom: 2rem!important;
        position: initial!important;

    }
    
.relative.mb-6.overflow-hidden.rounded-lg.bg-cover.bg-no-repeat.shadow-lg.dark\:shadow-black\/20 {

    height: auto!important;
}
}

.card-seo-big-card-content-container.right-card {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}


.casestudy-seo-img {
    max-width: 100%;
}

.relative.mb-6.overflow-hidden.rounded-lg.bg-cover.bg-no-repeat.shadow-lg.dark\:shadow-black\/20 {
    overflow: hidden;
    height: 329px;
}

.Tktok-content {
    text-align: center;
}

.tiktok-heading {
    width: fit-content;
}

.Tktok-content {
    width: fit-content;
    margin: auto;
}

h2.title-tiktok {
    font-size: 50px;
    background: black;
    color: white;
    padding: 0.5rem 2.5rem;
}

.Tktok-content {
    width: fit-content;
    margin: auto;
    position: absolute;
    right: 0;
    top: 20rem;
    left: 0;
    bottom: 0;
    z-index: 1;
}

h2.\31 0xincrease-title {
    font-size: 37px;
    color: black;
    font-weight: 800;
}

section.casestudy-seo.mb-32.text-center.md\:text-left {
    position: relative;
}

p.tiktok-para-cs.text-neutral-500.dark\:text-neutral-300 {
    font-size: 20px;
    font-weight: 700;
    color: black;
}

.seo-casestudycontainer.mx-auto.md\:px-6 {
    padding: 0rem 4rem;
}

/* ================section1 seo background ================ */