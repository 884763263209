input#exampleFormControlInput1 {
    border-bottom: 1px solid;
}

footer.bg-neutral-900.text-center.text-white {
    background: #010202f7 !important;
}

.Footer-container.px-6.pt-6.\.bg-gray-500 {
    background: #f0f0f0;
}

element.style {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

p.footer-para-text {
    text-align: justify;
}

.grid-checking-style {
    text-align: initial;
}

.footer.mb-6 {
    max-width: 100%;
}

.online-container-foorter {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 100%;
    width: 645px;
}

.display-flex-footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 32px;
    align-items: baseline;
    color: black;
}

h2.online-media-footer {
    font-size: 20px;
    margin-bottom: 10px;
}

ul.mb-0.list-none {
    color: white;
}

.p-4.text-center.paragrapg-all-component-font-family {
    background-color: rgb(216 216 216) !important;
    color: black;
}

a.m-1.h-9.w-9.rounded-full.border-2.border-white.uppercase.leading-normal.text-white.transition.duration-150.ease-in-out.hover\:bg-black.hover\:bg-opacity-5.focus\:outline-none.focus\:ring-0 {
    border: 1px solid #f0f0f0;
    background-image: linear-gradient(180deg, #00bbbe, #056c6e);
    color: white;
}
a.m-1.h-9.w-9.rounded-full.border-2.border-white.uppercase.leading-normal.text-white.transition.duration-150.ease-in-out.hover\:bg-black.hover\:bg-opacity-5.focus\:outline-none.focus\:ring-0:hover {
    color: black;
}
.text-white {
    color: black;
}