.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}






@font-face {
  font-family: Bultler-Black;
  src: url(/public/Butler_Black.otf);
}

@font-face {
  font-family: Bultler-Black;
  src: url(/public/Poppins-SemiBold.ttf);
}
@font-face {
  font-family: bold_stylish;
  src: url(/public/Montez-Regular.ttf);
}

.heading-all-component-font-style {

  font-family: Bultler-Black !important;

}

.brands-that-trust-us {
  font-family: bold_stylish !important;
  
}
.paragrapg-all-component-font-family {
  font-family: Poppins-SemiBold !important;
  font-size: 16px!important;
}
