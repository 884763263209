@media (max-width: 767px) {

    .subtitl {
        font-size: 32px;
        line-height: normal;
    }

    span.content-color-responsive {
        font-size: 18px !important;
        line-height: normal;
    }

    span.digital-marketing-color {
        font-size: 18px !important;
        line-height: normal;
    }
}

span.red {
    color: #f30000;
}

span.blue {
    color: #00b8bb;
}

span.yellow {
    color: #ffb500;
}

span.digital-marketing-color {
    font-size: 2rem;
    font-weight: 700;
    color: #000000;
}

.Digital-Marketing-Agency-heading {
    text-align: center;
    padding: 0rem 1rem;
}

span.content-color-responsive {
    font-size: 2rem;
    font-weight: 800;
    color: black;
}

.content-para-why-choose {
    max-width: 100%;
    margin: 0 auto;
    padding: 5px 20px;
    width: 1181px;
    text-align: revert;
}

/* -------------------- content-second-portion------------ */
@media (min-width: 782px) {

    .content-meet-our-team-container {
        flex-basis: 0;
        flex-grow: 1;
    }
}

@media (max-width: 768px) {
    .meet-our-team-container {
        flex-direction: column;
    }

    .content-meet-our-team-container,
    .img-team {
        flex: auto;
    }

    .meet-our-color {
        font-size: 18px !important;

    }

    .color-marketing-experts {
        font-size: 18px !important;
    }
}

.heading-meet-our-team {
    font-size: 22px;
    line-height: 1.2em;
    font-weight: 700;
    margin-bottom: 0.6rem;
}


p.strong-para-meet-our-team {

    color: black;
}

.content-four-card-heading {
    display: table;
    margin: 15px auto !important;
    background: #00bbbe;
    color: #ffffff;
    padding: 13px 19px;
    font-size: 18px;
    font-weight: 700;
}


.color-marketing-experts {
    font-size: 2rem;
    font-weight: 800;
    color: rgb(0, 0, 0);
}

.meet-our-color {
    font-size: 2rem;
    font-weight: 800;
    color: rgb(0, 0, 0);
}

p.content-four-card-para {
    width: 260px;
    margin: auto;
    padding: 0.2rem;
    color: black;
    text-align: center;
}

.content-four-card-some-reason-why-container {
    display: flex;
    justify-content: center;
    align-items: normal;
    flex-wrap: wrap;
    row-gap: 20px;
    margin-bottom: 3rem;
    column-gap: 20px;
    padding: 0.5rem;
}

.content-four-card-some-reason-why {
    border-radius: 10px;
}

.content-omc-why-choose-omc-container {
    background: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


p.content-para-why {
    color: black;
}

p.para-meet-our-team {
    color: black;
    text-align: justify;
}

/* ------------------new content and image ---------------------- */
.content-meet-our-team-container {
    flex-grow: 1;
    min-width: 0;
    word-break: break-word;
    overflow-wrap: break-word;
    font-size: 16px;
    box-sizing: border-box;

}

@keyframes slide-right {
    0% {
        transform: translateY(50%);
    }

    100% {
        transform: translateY(0);
    }
}

.meet-our-team-container {
    max-width: 100%;
    width: 1181px;
    margin: 0 auto;
    padding: 0px 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    column-gap: 20px;

    @media (max-width: 768px) {
        flex-direction: column-reverse;
    }
}

.img-team {
    flex: 1;
}

img.img-team-pic {
    max-width: 100%;
    border-radius: 10px;
}

.alignfull.border {

    position: absolute;
    content: '';
    left: 50%;
    bottom: -13px;
    margin-left: -16px;
    z-index: 9;
    background-color: #fff;
    background-repeat: no-repeat;
    border: solid #b1b4ae;
    border-width: 0px 1px 1px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    width: 25px;
    height: 25px;
}