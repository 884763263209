.para-content-div-child-container {
    max-width: 100%;
    width: 100%;
}

.overview-container-si {
    display: flex;
    justify-content: center;
    background: #ffffff;
    align-items: center;
    column-gap: 52px;
    padding-top: 3rem;
    padding-right: 3rem;
    padding-left: 3rem;
}

.content-main {
    color: black;
}

.si-data-img-div {
    background: #ffffff;
}

img.si-data-img {
    max-width: 100%;
    width: 100%;
    margin: auto;
}

h2.para-content-heading-si {
    font-size: 33px;
    margin-bottom: 20px;
    font-weight: 700;
}

img.si-img {
    max-width: none;
    width: auto;
}

.main-container- {
    max-width: 100%;
    width: 2500px;
    margin: auto;
}



@media screen and (max-width:1240px) {
    img.si-img {
        max-width: 100% !important;
        width: 100%;

    }

    .para-content-div-child-container {
        max-width: 100%;
        width: 100%;
    }

    .content-main {
        text-align: justify;
    }

    .border-radius {
        display: flex;
        justify-content: flex-end;
        position: relative;
        top: 0;
        right: 0;
    }

    .si-child-container {
        display: flex;
        flex-direction: column-reverse;
    }

    .background-white {
        height: 0px;
    }

    .img-si {
        max-width: 100%;
        width: 100%;
    }

    .overview-container-si {

        padding: 20px;
        flex-wrap: wrap;
    }

    .relative.mb-6.overflow-hidden.rounded-lg.bg-cover.bg-no-repeat.shadow-lg.dark\:shadow-black\/20 {
        height: auto !important;
    }

   
}

@media screen and (max-width:800px) {
    .display-responsive {
        display: flex;
        column-gap: 2rem;
        flex-wrap: wrap;
        justify-content: center;
    }

    .col-md-3.col-sm-6 {
        max-width: 100%;
        width: 100% !important;
    }
}


.display-responsive {
    display: flex;
    column-gap: 2rem;
    margin-top: 20px;
}

.row {
    display: flex;
    column-gap: 2rem;
    padding: 0rem 3rem;
    row-gap: 0rem;
    flex-wrap: wrap;
}

.service_box {
    margin-bottom: 20px;
    padding: 40px 20px;
    text-align: center;
    border-radius: 5px;
    height: 334px;
    background: rgb(21, 20, 20);
    transition: all 0.30s ease 0s;
}

.service_box:hover {
    background: #0d8e90;
    cursor: pointer;
}

.service_box .service_icon {
    width: 136px;
    height: 100px;
    margin: 0 auto;
    font-size: 35px;
    line-height: 70px;
    border-radius: 50px;
    transform: translateY(0);
    transition: all 0.30s ease 0s;
}

.service_box:hover .service_icon {
    transform: translateY(-100%);
}

.service_box .service_icon i {
    color: #333;
}

.service_box h3 {
    position: relative;
    top: 40px;
    margin: 0;
    color: #fff;
    font-size: 23px;
    text-transform: uppercase;
    transform: translateY(0%);
    transition: all 600ms cubic-bezier(0.68, -0.55, 0.265, 1.55) 0s;
}

.service_box:hover h3 {
    top: -40px;
}

.service_box p {
    color: #fff;
    margin: 0;
    opacity: 0;
    transition: all 0.30s linear 0s;
}

.service_box:hover p {
    opacity: 1;
}

img.icon-img-si-card {
    max-width: 100%;
}

.col-md-3.col-sm-6 {
    max-width: 100%;
    width: 50%;
}