nav.navbar {
  position: fixed;
  width: -webkit-fill-available;
  z-index: 222222;
}
.navbar-links a:hover , .dropdown-btn:hover {
  color: rgb(0 187 190);
  color: rgb(0 187 190);
}

.navbar-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 110px;
  background: #ffffff29;
  font-size: 21px;
  position: relative;
  max-width: 100%;
  z-index: 22;
  box-shadow: 0px 0px 6px;
  width: 2500px;
  margin: auto;
}

.navbar-logo {
  font-size: 1.5rem;
  font-weight: bold;
  column-gap: 14px;
  display: flex;
  align-items: center;
}

.logo-img {
  max-width:106px;
}

.navbar-links {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 20px;
}


.navbar-links a {
  text-decoration: none;
  font-weight: 700;
  color: black;
  font-size: 21px;
  padding: 0px 4px;
}
.number-nav {
  text-decoration: none;
  font-weight: 700;
  color: #00bbbe;
  font-size: 21px;
}
.scrolled {
  background-color: white!important;
}
.hamburger-menu {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  width: 30px;
  height: 20px;
  margin-right: 1rem;
}

.bar {
  width: 100%;
  height: 4px;
  background-color: #000000;
  transition: transform 0.2s ease;

}

img.phone-icon-img {
  filter: drop-shadow(0px 0px 1px cyan);
  padding-left: 5rem;
}
.active .bar:nth-child(1) {
  transform: rotate(-45deg) translate(-5px, 6px);
}

.active .bar:nth-child(2) {
  opacity: 0;
}

.active .bar:nth-child(3) {
  transform: rotate(45deg) translate(-5px, -6px);
}

.navbar-logo:hover,
.navbar-links a:hover {
  transform: perspective(500px) rotateX(10deg);
  transition: transform 0.3s ease;
}

.navbar-logo:hover {
  transform: perspective(500px) rotateX(10deg);
  transition: transform 0.3s ease;
}

.hamburger-menu:hover .bar {
  transform: scale(1.2);
  transition: transform 0.3s ease;
}

@media screen and (max-width: 1200px) {
  .logo-img {
    max-width: 77px;
}
  .navbar-links {
    display: none;
    flex-direction: column;
    align-items: center;
    background-color: #333;
    position: absolute;
    top: 94px;
    left: 0;
    width: 100%;
    z-index: 10;
  }
  .number-nav {
    font-size: 16px;
}
  .search-bar-hidden{
    display: none;
  }
  .navbar-links.active {
    display: flex;
    background: rgba(255, 255, 255, 0.83);
    z-index: 111;
    backdrop-filter: blur(21px);
    padding-left: 2rem;
    align-items: flex-start;
  }

  .navbar-links a {
    margin: 1rem 0;
  }

  .hamburger-menu {
    display: flex;
  }
  .dropdown-btn {
    margin-left: 0rem!important;
    margin: 1rem 0;
  }
  .navbar-container{
    justify-content: space-between;
  }
  .dropdown-content {
    list-style: none;
    position: absolute;
    top: -7em;
    visibility: hidden;
    overflow: hidden;
    z-index: 200;
    left: -30px;
}
.dropdown-content li {
  width: 244px;
  font-size: 12px;
}
.navbar-container {
  height: 77px;
}
}

h2.number-color {
  color: #fcffff;
}
.phone-number-container {
  display: flex;
  justify-content: center;
  align-items: center;
}


.dropdown {
  position: relative;
}

.dropdown-btn {
  align-items: center;
  display: flex;
  justify-content: space-between;
  text-decoration: none;
  font-weight: 700;
  font-size: 21px;;
  color: #000000;
  column-gap: 9px;
  border-radius: 4px;
  cursor: pointer;
}

.arrow {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 6px solid #000000;
  transition: transform ease-in-out 0.3s;
  position: relative;
}


.dropdown-content {
  list-style: none;
  position: absolute;
  top: 1.5em;
  width: 261%;
  visibility: hidden;
  overflow: hidden;
  z-index: 200;
}

ul.dropdown-content {
  background: #ffffff;
  padding: 12px;
  box-shadow: 0px 0px 8px black;
}

.dropdown-content li {
  border-radius: 0.5em;
  position: relative;
  left: 100%;
  transition: 0.5s;
  transition-delay: calc(60ms * var(--delay));
}

.dropdown:hover .dropdown-content li {
  left: 0;
}

.dropdown:hover .dropdown-content {
  visibility: visible;
}

.dropdown:hover .arrow {
  transform: rotate(180deg);
}


.dropdown-content li a {
  display: block;
  color: #0a0909;
  margin: 0.4em 0;
  text-decoration: none;
}

/* ==============seacrch bar =============== */

.text-white {
  color: gray;
}

p.onestop {
  font-family: 'Open Sans', sans-serif, Arial, Helvetica;
  font-size: 15px;
  font-weight: 300;
  font-style: italic;
  color: #757575;
}

.container-number-serchbar {
  display: inline-flex;
  align-items: center;
  column-gap: 29px;
}