.marquee {
  overflow: hidden;
  white-space: nowrap;
  height: 121px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(113deg, #ebebeb 0, hsl(0deg 0% 98%) 54%, hsla(0, 0%, 98%, 0.7) 0%, hsl(0deg 0% 95.13%) 57%, hsl(0deg 0% 81.53% / 27%));
  width: 98.7vw;
}

.marquee-content {
  animation: scrolling 42s linear infinite;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 84px;
}

.marquee-item {
  flex: 0 0 200px;
  display: flex;
  justify-content: center;
}

.marquee-item img {
  display: block;
  width: 150px;
  max-height: 104px;
  max-width: 80%;
}

@keyframes scrolling {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

.client-name-that.trust.us {
  display: inline-flex;
  flex-direction: column;
  height: 121px;
  position: absolute;
  z-index: 22;
  background: #ffffff;
}

.bg-linear-g-brands {
  background-image: linear-gradient(113deg,
      #baa4a4 0,
      hsla(0, 32%, 100%, 100.9) 54%,
      hsla(0, 0%, 98%, 0.7) 0%,
      hsla(0, 0%, 100%, 7.2) 57%,
      hsl(0deg 32% 6% / 27%));
  position: absolute;
  height: 138px;
  width: 99vw;
}

.brand-client {
  padding: 2.2rem 0px;
}


h2.heading-client-brand {
  font-size: 2.875rem;
  padding: 0px 2rem;
  font-weight: 800;
  color: #383636;
  line-height: 3px;
}

h2.client-heading-trust {
  font-size: 3.5rem;
  font-weight: 800;
  color: #00bbbe;
  padding: 0 3rem;
}

@keyframes grayscaleToColor {
  from {
    filter: grayscale(1);
  }

  to {
    filter: grayscale(0);
  }
}

.client-logo-owl {
  position: relative;
  animation: grayscaleToColor 1s ease-in-out;
}

@media screen and (max-width: 800px) {
  .client-name-that.trust.us {
    background: #ffffff;
    position: relative;
    display: block;
    text-align: center;
    /* margin: 1rem; */
  }

  h2.heading-client-brand {
    font-size: 1.5rem;
    padding: 0px 2rem;
    font-weight: 800;
    color: #383636;
    line-height: 3px;
  }

  h2.client-heading-trust {
    font-size: 2.2rem;
    font-weight: 800;
    padding: 0 3rem;
  }
}

@media screen and (max-width: 281px) {
  h2.heading-client-brand {
    font-size: 1.7rem !important;
  }

  h2.client-heading-trust {
    font-size: 2rem;
  }

  .client-name-that.trust.us {
    background: #ffffff;
    position: relative;
    display: block;
    text-align: center;
    margin: 1rem;
  }
}

.client-logo-owl {
  position: relative;
  animation: grayscaleToColor 1s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
}