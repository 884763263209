element.style {
    border: 1px;
}

iframe {
    width: 100%;
    height: 500px;
}


.contact-form-col.col-lg-6.col-md-6 {
    width: 500px;
}

.contact.breadcrumb__text.breadcrumb__text {
    display: flex;
    width: 92vw;
    justify-content: space-between;
}

.contact-breadcrumb__links.breadcrumb__links {
    display: flex;
}

/*------------------- contact section 1   -----------------------*/
.get-in-touch-background {
    background-image: url("/public/assets/backgroundimg/map.jpg");
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
}

p.contact-para-p {
    color: rgb(255 255 255);
    font-size: larger;
}

h1.contact-head {
    font-size: 50px;
    font-weight: 700;
    color: white;
}

.get-in-touch-para {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: 20px;
    font-family: revert;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 1rem;
    overflow-wrap: break-word;
    transition: opacity 2.5s ease, transform 2.5s ease;
}

.get-in-touch-para.animate {
    opacity: 1;
    transform: translateX(0);
    height: 400px;
    justify-content: center;

}

.contact-heading-container {
    color: rgb(0, 0, 0);
}


/*-------------top of the contact section ------------*/
.contact-us-main {
    padding-top: 7rem;
    background: #efefef;
}

.contact-us-top-section {
    font-size: 32px;
    font-weight: 900;
    font-family: emoji;
}

.contact-breadcrumb__links.breadcrumb__links {
    display: flex;
    align-items: center;
}

a.style-link-contact-home {
    font-weight: 600;
    border-bottom: 1px solid;
}

span.style-contact-us {
    color: #0000007a;
}

/*-------hover---------*/
a.style-link-contact-home {
    color: #18272F;
    position: relative;
    text-decoration: none;
}

a.style-link-contact-home::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    border-radius: 4px;
    background-color: #18272F;
    bottom: 0;
    left: 0;
    transform-origin: right;
    transform: scaleX(0);
    transition: transform .3s ease-in-out;
}

a.style-link-contact-home:hover::before {
    transform-origin: left;
    transform: scaleX(1);
}

.contact-button-style {
    --c: #6c6b68;
    color: #000000;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
    font-family: sans-serif;
    letter-spacing: 0.1em;
    text-align: center;
    line-height: 3em;
    position: relative;
    overflow: hidden;
    z-index: 1;
    transition: 0.5s;
    margin: auto;
    border-radius: 6px;
    width: -webkit-fill-available;
    margin-bottom: 2rem;
    background-image: linear-gradient(180deg, #00bbbe, #098688);
}

.contact-button-style span {
    position: absolute;
    width: 94%;
    height: 152%;
    background-image: linear-gradient(180deg, #00fcf6fa, #000000);
    transform: translateY(198%);
    border-radius: 50%;
    left: calc((var(--n) - 1) * 25%);
    transition: 0.5s;
    transition-delay: calc((var(--n) - 1) * 0.1s);
    z-index: -1;
}

.contact-button-style:hover {
    color: black;
}

.contact-button-style:hover button span {
    transform: translateY(0) scale(2);
}

/* --------------------   contact form and text styling   ----------------   */

.contact-form-row-row {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 3rem;
    padding: 3rem;
    row-gap: 30px;
    background: #efefef;
}

.contact-form-main-col {
    height: 50px;
    width: 100%;
    border: 1px solid #6c6b68;
    padding-left: 20px;
    font-size: 15px;
    color: #7a7373;
    margin-bottom: 30px;
    border-radius: 4px;
}

.contact-text-area {
    height: 115px;
}

.style-contact-form {
    display: flex;
    column-gap: 22px;
    margin-top: 4rem;
}

/*--------contact section 2 ---------------*/
.contact-section-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 30vh;
}

.contact-detail-container {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 4rem;
    row-gap: 2rem;
    padding: 2rem;
    color: white;
    font-size: 1.2rem;
}

.logo-container {
    margin-bottom: 1rem;
}

.logo-container img {
    width: 100px;
    height: auto;
}

h1.heading-title-question {
    font-weight: 900;
    font-size: 30px;
}

.h5.heading-text {
    font-size: 11px;
    font-weight: 500;
}

a.h5.title {
    color: black;
    font-size: 24px;
}

section.kc-elm.kc-css-546894.kc_row {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 1em;
    padding-top: 4rem;
    background: #efefef;
}

/* ==========================================stylink or contact us section 2 =================================== */

.kc-wrap-columns {
    display: flex;
    column-gap: 50px;
    row-gap: 30px;
    align-items: flex-end;
}

.contacts-item .icon {
    width: 70px;
    height: 70px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 30px;
}

.img-contact-us-section-2 {
    max-width: 100%;
    height: auto;
    display: inline-block;
    vertical-align: middle;
}

.sub-title {
    color: #959a9f;
}

.kc-elm.kc-css-13966.crumina-module.contacts-item {
    max-width: 100%;
    width: 358px;

}

@media screen and (max-width:1000px) {
    .kc-wrap-columns {

        flex-wrap: wrap;
    }

    a.h5.title {
        color: black;
        font-size: 18px !important;
    }

    h1.contact-head {
        font-size: 18px!important;
    }
    .contact-us-top-section {
        font-size: 18px;
        font-weight: 900;
        font-family: emoji;
    }

    .row {
        display: flex;
        column-gap: 2rem;
        padding: 20px !important;
        row-gap: 3rem;
        flex-wrap: wrap;
    }

    p.contact-para-p {
        font-size: 16px !important;
    }

    .h5.heading-text {
        font-size: 15px;
    }
}